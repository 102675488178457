<template>
  <div class="box">
    <h1>欢迎登录长三角（义乌）生态环境研究中心</h1>
    <div class="card">
      <div class="left">
        <img src="../assets/2.png" width="100%" height="100%" />
      </div>
      <div class="middle">
        <img src="../assets/1.png" width="100%" height="100%" />
      </div>
      <div class="right">
        <div class="input_box">
          <div class="icon">
            <img src="../assets/user.png" width="100%" height="100%" />
          </div>
          <div class="line"></div>
          <div>
            <input type="text" placeholder="请输入账号" v-model="accountNumber" maxlength="18" />
          </div>
        </div>
        <div class="input_box">
          <div class="icon">
            <img src="../assets/password.png" width="100%" height="100%" />
          </div>
          <div class="line"></div>
          <div>
            <input type="password" placeholder="请输入密码"  v-model="password"/>
          </div>
        </div>
        <button class="btn" @click="loginBtn">登录</button>
        <div class="btnName">
        <div class="bom_title" @click="toLogin('/register')">立即注册 >
          </div>
          <div style="color:#397AFF;cursor: pointer;" @click="toLogin('/forgotPW')">忘记密码？</div>

        </div>
        <div class="radio_box">
            <el-checkbox v-model="checked">记住我</el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {login} from '../api/api'
import {getUserInfo} from '../api/api'

export default {
  data() {
    return {
      accountNumber: "", //账号
      password:'',//密码
      checked:false,
    }
  },mounted(){
    this.accountNumber = localStorage.getItem('accountNumber')
    this.password = localStorage.getItem('password')
  },methods:{
    //登录
    async getLogin(){
      let that = this
      let res = await login({
        password:that.password,
        username:that.accountNumber
      })
      if(res.ResultCode == 0){
        localStorage.setItem('token',res.Data.token)
        that.$message({
          message: '登录成功',
          type: 'success'
        });
        that.getUserInfo2()
        setTimeout(()=>{
          that.$router.push('/index')
        },1500)
      }else{
        that.$message({
          message: res.ResultInfo,
          type: 'error'
        });
      }
    },
    loginBtn(){
      let that = this
      if(!that.accountNumber){
        that.$message({
          message: '账号不能为空',
          type: 'error'
        });
        return
      }
      if(!that.password){
        that.$message({
          message: '密码不能为空',
          type: 'error'
        });
        return
      }
      if(that.checked){
        localStorage.setItem('accountNumber',that.accountNumber)
        localStorage.setItem('password',that.password)
      }
      that.getLogin()
    },
    //获取用户信息
    async getUserInfo2(){
      let res = await getUserInfo()
      if(res.ResultCode == 0){
        console.log(res,'res')
        localStorage.setItem('userInfo',JSON.stringify(res.user))
      }
    },
    //跳转登录页面 忘记密码页
    toLogin(path){
      this.$router.push(path)
    }
  }
};
</script>

<style scoped lang="scss">
.box {
  background: 100vw;
  height: 100vh;
  background-image: url("../assets/bg.png");
  background-size: 100vw 100vh;
  padding-top: 1px;
  box-sizing: border-box;
  h1 {
    font-size: 50px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    margin-top: 135px;
  }
  .card {
    width: 977px;
    height: 579px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 40px;
    margin-top: 63px;
    background-image: url("../assets/bg1.png");
    background-size: 100%;
    display: flex;
    flex-direction: row;
    .left {
      width: 346px;
      height: 308px;
      margin-top: 117px;
      margin-left: 74px;
      margin-right: 42px;
    }
    .middle {
      width: 1px;
      height: 447px;
      margin-top: 14px;
      margin-right: 46px;
    }
    .right {
      padding-top: 121px;
      .input_box {
        width: 396px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        .icon {
          width: 26px;
          height: 26px;
          margin: 12px 14px;
        }
        .line {
          width: 1px;
          height: 24px;
          background: #e5e5e5;
        }
        input {
          border: none;
          margin-left: 14px;
          font-size: 18px;
          outline: none; /*点击之后。不出现边框*/
          width: 300px;
        }
      }
      .btn {
        width: 414px;
        height: 56px;
        background: #397aff;
        box-shadow: 0px 3px 13px 0px rgba(25, 45, 168, 0.28);
        border-radius: 8px;
        border: none;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-top: 34px;
      }
      .btnName{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 35px;
      }
      .bom_title{
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        text-align: center;
        cursor: pointer;
      }
      .radio_box{
        margin-top: 60px;
      }
    }
  }
}
</style>
